import ShanghaiRSNTable from "@/views/ShanghaiManifest/components/ShanghaiRSNTable.vue";
import AddManifestContactDialog from "@/views/ShanghaiManifest/components/contacts/AddManifestContactDialog.vue";
import AddManifestRSNDialog from '@/views/ShanghaiManifest/components/contacts/AddManifestRSNDialog'
export default {
    components: {
        AddManifestContactDialog,
        ShanghaiRSNTable,
        AddManifestRSNDialog,
    },
    data() {
        return {
            type: {
                title: '通知人',
                code: 2
            },
            tableHeaders: this.RESETTABLE.shanghaiSN,
        };
    },
    created() {
    },
    methods: {
        refreshList() {
            this.$refs.ShanghaiRSNTable.getList();
        },
        edit(item) {
            this.$refs.AddManifestRSNDialog.init(item);
        },

    },
};
